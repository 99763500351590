import React from 'react';
import styled from 'styled-components';
import theme from '../utils/theme';

const Hero = styled.div`
  background: rgba(38, 38, 44, 0.7);
  height: 355px;
  padding: 80px;

  .image-container {
    display: block;
    height: 355px;
    max-width: 100%;
    width: calc(100% - 382px);
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 382px;
    right: 0;
    z-index: -1;
  }

  .gatsby-resp-image-wrapper,
  .gatsby-resp-image-link {
    height: 100%;
  }

  img {
    object-fit: cover;
  }

  .anchor {
    display: none;
  }

  h1 {
    color: ${theme.primaryLight};
    margin-bottom: 44px;
  }

  p {
    font-size: 24px;
    line-height: 30px;
    color: ${theme.textWhite};
    max-width: 668px;
  }

  @media (max-width: 1439px) {
    .image-container {
      left: 0;
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    h1 {
      margin: 30px 0 20px;
      font-size: 34px;
      line-height: 40px;
    }

    p {
      font-size: 18px;
      line-height: 29px;
    }
  }

  @media (max-width: 768px) {
    height: 490px;
    padding: 80px 20px;

    h1 {
      margin: 40px auto 10px;
      max-width: 280px;
    }

    p {
      margin: 0 auto;
      max-width: 280px;
    }

    .image-container {
      height: 490px;
    }
  }
`;

export default ({html}) => {
  React.useEffect(() => {
    const wrapper = document.getElementById('main-hero');
    const imageContainer = wrapper.children[0];
    if (!imageContainer.classList.contains('image-container')) {
      imageContainer.classList.add('image-container');
    }
  });
  return <Hero id="main-hero" dangerouslySetInnerHTML={{__html: html}} />;
};
