import React from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import theme from '../utils/theme';
import triangle from '../../static/images/triangle_medium.svg';


const ProductOverviews = styled.div`
  position: relative;
  padding: 60px 80px;

  h3 {
    margin-top: 30px;
  }

  h4 {
    position: relative;
  }

  h4:after {
    content: '';
    display: block;
    position: absolute;
    top: 15px;
    left: 100px;
    width: calc(100% - 100px);
    height: 1px;
    background: ${theme.textBlack};
    opacity: 0.1;
  }

  .product-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  .product {
    max-width: 280px;
    margin-right: 32px;
  }

  .anchor {
    display: none;
  }

  p {
    margin-top: 18px;
    font-size: 16px;
  }

  a {
    font-size: 16px;
    text-decoration: none;
  }
  a:before {
    content: url(${triangle});
    margin-right: 8px;
    transition: 200ms ease-in-out;
  }
  a:hover:before {
    margin-right: 4px;
    margin-left: 4px;
  }

  @media (max-width: 768px) {
    padding: 60px 0;
    max-width: 280px;
    margin: 0 auto;

    p {
      margin-top: 4px;
    }
  }
`;

const IndexPage = ({
  data: {
    allMarkdownRemark: {edges},
  },
}) => {
  const heroContent = edges.find(edge => edge.node.frontmatter.type === 'hero').node.html;
  const overViewContent = edges.find(edge => edge.node.frontmatter.type === 'overview').node.html;

  return (
    <Layout>
      <SEO title="Docs" keywords={[`gatsby`, `application`, `react`]} />
      <Hero html={heroContent} />
      <ProductOverviews dangerouslySetInnerHTML={{__html: overViewContent}} />
    </Layout>)
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {id: {eq: "main"}}}) {
      edges {
        node {
          html
          frontmatter {
            type
          }
        }
      }
    }
  }
`;
